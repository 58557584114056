import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'
import logo from '../../content/images/logo.svg'
import lp from '../../content/images/lp.png'

class Corporate extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta
          site={siteMetadata}
          title="遺品整理サービスを提供されている企業の法人・個人事業主の方へ"
        />
        <header className="jumbotron rounded-0">
          <div className="container mw7">
            <h1 className="d-flex align-items-top">
              遺品整理サービスを提供されている企業の法人・個人事業主の方へ
            </h1>
            <p className="mb-0 text-muted font-weight-bold">
              eイーセイリへの入会ご希望の方へ。
            </p>
          </div>
        </header>

        <section>
          <div className="container mw7 py-3">
            <h2>
              <ruby>
                <rb>eイーセイリ</rb>
                <rp>（</rp>
                <rt>いいーせいり</rt>
                <rp>）</rp>
              </ruby>
              への入会方法
            </h2>
            <p className="text-muted text-justify font-weight-bold">
              法人・個人事業主の方は、加入に当たって審査がございます。
            </p>
            <p>
              遺品整理企業の方にeイーセイリをご利用いただくことで、単一の企業や個人事業主では解決できな諸問題を解決することができます。
            </p>
            <p>
              eイーセイリが運営する「一括見積もりサービス」を筆頭に、相続などの法律についての専門家や、独自の知見を持つ専門家との交流など、遺品整理サービスを提供する企業にとって、より多くの機会と安定した受注の提供を実現します。
            </p>
            <figure className="figure my-3">
              <img
                src={lp}
                alt="ランディングページの画像"
                className="img-fluid border border-light shadow"
              />
              <figcaption className="figure-caption">
                eイーセイリが運営する遺品整理優良見積もりサービス
              </figcaption>
            </figure>
            <h2 className="mt-5">一括見積もりサービス参加について</h2>
            <p>
              一括見積もりにご参加いただくと、以下のような利点がございます。
            </p>
            <ul className="pl-3">
              <li>
                eイーセイリによる一括見積もりサービスによって、受注を安定的に得ることができます。
              </li>
              <li>他社あるいは他業種、専門家との連携が可能になります。</li>
              <li>遺品整理サービス提供に関する情報共有の機会がございます。</li>
              <li>有資格者に、関係法令や技術的な相談を行うことができます。</li>
            </ul>
            <h2 className="mt-5">案件のご紹介方法</h2>
            <ul className="pl-3">
              <li>
                会員限定のポータルサイトおよびメールにて随時見積もりの内容をお届けします。
              </li>
              <li>
                ご興味のある案件のみ、eイーセイリまで参加意思をお伝えください。
              </li>
              <li>お客様の連絡先や現場詳細をご案内いたします。</li>
            </ul>
            <h2 className="mt-5">一括見積もりサービス参加の要件</h2>
            <div className="alert alert-secondary mb-5">
              <ul className="mb-0 pl-3">
                <li>
                  遺品整理士サービスを提供し、営業実績のある法人および個人事業主であること。
                </li>
                <li>法人の場合には、過去3年以上の営業実績があること。</li>
                <li>個人事業主の場合には、過去5年以上の営業実績があること。</li>
                <li>
                  古物商であること。すなわち、サービス提供地域または営業所を管轄する都道府県公安委員会により古物商許可を得ており、許可の下、古物商の営業実績があること。
                </li>
                <li>
                  解体やリフォームをサービスとして告知・広告している場合には、建設業許可や解体工事業の登録があり、該当の分野で営業実績を持つこと。
                </li>
              </ul>
            </div>
            <p>
              ご参加いただく場合には、eイーセイリの定める「サービス参加規定」と「サービス運営倫理規程」を事前にお渡しし、遵守いただけるか確認をお願いしております。
            </p>
            <p>
              ご参加いただく場合には、すでに参加の他社からの紹介であっても、該当する許可証の写しを添えた「参加申込書」の記入・送付と「サービス参加規定」および「サービス運営倫理規程」の同意が必要です。
            </p>
            <p>
              消費者・エンドユーザーの皆様の安心のために、厳格な運用をいたしますので、ご理解とご協力をよろしくお願い申し上げます。
            </p>
            <p>
              サービスにご参加いただき、受注に至った場合に限り、手数料としてお客様へのご請求金額（お見積もり金額ではありません）の4.7〜7.0%（3万円以下の場合には、一律1,000円）をご請求金額に応じてお支払いただきます。
            </p>
            <h2 className="mt-5">一括見積もりサービス参加の流れ</h2>
            <div className="alert alert-info mb-5">
              <ol className="mb-0 pl-3">
                <li>参加要件を満たしておられるか、ご確認ください。</li>
                <li>eイーセイリ宛てにお申し込みの意思をお伝えください。</li>
                <li>
                  eイーセイリから参加規定と参加申込書を郵送あるいは電子メールでお送りします。
                </li>
                <li>必須事項をご記入の上、特定記録郵便にてご郵送ください。</li>
                <li>
                  eイーセイリに書面が到着後、書類不備のない場合に限り、審査を行います。
                </li>
                <li>
                  審査の可否に関わらず、お電話で結果をお知らせし、承認された場合には、サービス参加通知書をお送りいたします。
                </li>
                <li>
                  即日一括見積もりについての参加情報をお届けします。お見積もりにご協力いただき、お客様がお選びいただければ、当日から作業が可能です。
                </li>
              </ol>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Corporate
